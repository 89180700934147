<template>
  <div>
    <div class="container-fluid">
      <div class="taxman_img"></div>
      <h1>WHT Calculator for Goods</h1>
      <p>Withholding Tax Calculator</p>
      <h3>GST & Income Tax Withholding Calculator</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>

<style scoped>
.taxman_img {
  background-image: url(../../public/Taxman_logo.png);
  width: 25vw;
  height: 15vh;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
