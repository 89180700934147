<template>
  <div>
    <hr />
    <div class="container-fluid">
      <!-- Dropdown for Filer, Non-Filer, Not Confirmed -->
      <p>
        Just
        <a
          href="https://iris.fbr.gov.pk/#verifications"
          class="font-weight-bold"
          target="_blank"
          >click this link</a
        >
        to confirm Tax Payer Status directly from FBR Records.
      </p>
      <label for="statusDropdown">Select Status</label>
      <select
        class="form-control InputFields"
        id="statusDropdown"
        v-model="selectedStatus"
        @change="handleStatusChange"
      >
        <option value="">Select</option>
        <option value="Filer">Filer</option>
        <option value="Non-Filer">Non-Filer</option>
        <!-- <option value="Not Confirmed">Not Confirmed</option> -->
      </select>

      <!-- Conditional Rendering Based on Dropdown Selection -->
      <div v-if="selectedStatus === 'Filer' || selectedStatus === 'Non-Filer'">
        <!-- Display Verified Name -->
        <div v-if="generalVerifiedName">
          <p class="font-weight-bold">
            Verified Name:
            <span class="text-primary font-weight-bolder">
              {{ generalVerifiedName }}
            </span>
            <span class="text-danger">
              {{ statusDate }}
            </span>
          </p>
        </div>
        <!-- Display Radio Buttons -->
        <div class="radioButtons">
          <p class="font-weight-bold">
            Service Category / Vendor Status <span style="color: red">*</span>
          </p>
          <div v-if="selectedStatus === 'Filer'">
            <div
              v-for="(option, index) in filerOptions"
              :key="index"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                name="vendorStatus"
                :id="option.id"
                :value="option.value"
                v-model="selectedVendorStatus"
                @change="calculateIncomeTaxWithholding"
              />
              <label class="form-check-label" :for="option.id">
                {{ option.label }}
              </label>
            </div>
          </div>
          <div v-else-if="selectedStatus === 'Non-Filer'">
            <div
              v-for="(option, index) in nonFilerOptions"
              :key="index"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                name="vendorStatus"
                :id="option.id"
                :value="option.value"
                v-model="selectedVendorStatus"
                @change="calculateIncomeTaxWithholding"
              />
              <label class="form-check-label" :for="option.id">
                {{ option.label }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Selection Field for NTN or CNIC -->
      <!-- <div v-if="selectedStatus === 'Not Confirmed'">
        <label for="idType">Select ID Type</label>
        <select
          class="form-control InputFields"
          id="idType"
          v-model="selectedIdType"
          @change="handleIdTypeChange"
        >
          <option value="">Select</option>
          <option value="ntn">NTN</option>
          <option value="cnic">CNIC</option>
        </select> -->

      <!-- NTN Input Field -->
      <!-- <div v-if="selectedIdType === 'ntn'">
          <label for="NTN">NTN</label>
          <input
            type="text"
            class="form-control InputFields"
            id="NTN"
            v-model="ntnInput"
            placeholder="Enter NTN (e.g., xxxxxxx-x)"
            @input="formatNTNInput"
          />
        </div> -->

      <!-- CNIC Input Field -->
      <!-- <div v-if="selectedIdType === 'cnic'">
          <label for="CNIC">CNIC</label>
          <input
            type="text"
            class="form-control InputFields"
            id="CNIC"
            v-model="cnicInput"
            placeholder="Enter CNIC (e.g., xxxxxxxxxxxxx)"
            @input="formatCNICInput"
          />
        </div>
        <div v-if="selectedIdType">
          <button class="btn btn-primary" @click="verifyNotConfirmed">
            Verify
          </button>
        </div>
      </div> -->

      <!-- <div v-if="showAdditionalFields">
         

         <div class="SalesTaxPurpose">
          <p class="font-weight-bold">Vendor Status for Sales Tax Purpose</p>
          <div class="VendorStatusRadio">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="vendorStatusSalesTaxPurpose"
                id="registered"
                value="registered"
                v-model="selectedSalesTaxStatus"
              />
              <label class="form-check-label" for="registered">
                Registered
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="vendorStatusSalesTaxPurpose"
                id="unRegistered"
                value="unRegistered"
                v-model="selectedSalesTaxStatus"
              />
              <label class="form-check-label" for="unRegistered">
                Unregistered
              </label>
            </div>
          </div>
        </div> 

        <label for="vendorStatusSalesTaxPurpose"
          >Vendor Status for Sales Tax Purpose</label
        >
        <select
          class="form-control InputFields"
          id="vendorStatusSalesTaxPurpose"
          v-model="selectedSalesTaxStatus"
          @change="handleSalesTaxStatusChange"
        >
          <option value="registered">Registered</option>
          <option value="unregistered">Unregistered</option>
          <option value="notConfirmed">Not Confirmed</option>
        </select>
      </div> -->

      <div v-if="isVerified">
        <p class="font-weight-bold mt-2" for="vendorStatusSalesTaxPurpose">
          Vendor Status for Sales Tax Purpose
        </p>
        <select
          class="form-control InputFields"
          id="vendorStatusSalesTaxPurpose"
          v-model="selectedSalesTaxStatus"
          @change="handleSalesTaxStatusChange"
        >
          <option value="">Select</option>
          <option value="registered">Registered</option>
          <option value="unregistered">Unregistered</option>
          <!-- <option value="notConfirmed">Not Confirmed</option> -->
        </select>
      </div>

      <!-- NTN Input Field for "Not Confirmed" Sales Tax Status -->
      <!-- <div v-if="selectedSalesTaxStatus === 'notConfirmed'">
        <label for="ntnForSalesTax">NTN</label>
        <div>
          <input
            type="text"
            class="form-control InputFields"
            id="ntnForSalesTax"
            v-model="ntnInputForSalesTax"
            placeholder="Enter NTN (e.g, xxxxxxx)"
            maxlength="7"
            @input="formatNTNInputSales"
          />
          <button class="btn btn-primary" @click="verifySalesTaxNTN">
            Verify
          </button>
        </div>
      </div> -->

      <!-- Display verified name if available -->
      <div v-if="salesTaxVerifiedName">
        <p class="font-weight-bold">
          Name:
          <span class="text-primary font-weight-bolder">{{
            salesTaxVerifiedName
          }}</span>
          <span class="text-danger">
            {{ statusDate }}
          </span>
        </p>
      </div>

      <div>
        <p class="font-weight-bold mt-2">
          Vendor Status for Income Tax Purpose
        </p>
        <label for="GrossInvoiceAmount">Gross Invoice Amount</label>
        <input
          type="text"
          class="form-control InputFields"
          id="GrossInvoiceAmount"
          v-model="formattedGrossInvoiceAmount"
          @input="updateGrossInvoiceAmount"
          placeholder="0"
          :disabled="!selectedStatus || !selectedVendorStatus"
        />
        <label for="IncomeTaxWithHolding">Income Tax WithHolding</label>
        <input
          disabled
          type="text"
          class="form-control InputFields"
          id="IncomeTaxWithHolding"
          :value="formattedIncomeTaxWithholding"
          placeholder="0"
        />
        <label for="GSTWithHolding">GST Withholding</label>
        <input
          disabled
          type="text"
          class="form-control InputFields"
          id="GSTWithHolding"
          :value="formattedGSTWithholding"
          placeholder="0"
        />
        <label for="PaybaleToVendor">Payable To Vendor</label>
        <input
          disabled
          type="text"
          class="form-control InputFields"
          id="PaybaleToVendor"
          :value="formattedPayableToVendor"
          placeholder="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculatorComponent",
  data() {
    return {
      selectedStatus: "", // Selected status from dropdown
      ntnInput: "",
      cnicInput: "",
      selectedIdType: "", // Newly added data property
      selectedVendorStatus: "",
      selectedSalesTaxStatus: "",
      showAdditionalFields: false,
      isVerified: false,
      generalVerifiedName: "", // Stores the name after successful general NTN verification
      salesTaxVerifiedName: "", // Stores the name after successful Sales Tax NTN verification
      grossInvoiceAmount: "", // Input field for gross invoice amount
      incomeTaxWithholding: 0, // Calculated income tax withholding value
      gstWithholding: 0,
      statusDate: this.getMondayOfCurrentWeek(),

      taxRates: {
        filer: {
          company: 0.05,
          tollManufacturingCompany: 0.09,
          others: 0.055,
          tollManufacturingOthers: 0.11,
          distributorOfCigarettes: 0.025,
          distributorOfPharmaProducts: 0.01,
          saleOfRice: 0.015,
          suppliesOfGold: 0.01,
          distributors: 0.0025,
        },
        nonFiler: {
          company: 0.1,
          tollManufacturingCompany: 0.18,
          others: 0.11,
          tollManufacturingOthers: 0.22,
          distributorOfCigarettes: 0.05,
          distributorOfPharmaProducts: 0.02,
          saleOfRice: 0.03,
          suppliesOfGold: 0.02,
          distributors: 0.11,
        },
      },

      filerOptions: [
        { id: "company", value: "company", label: "Company" },
        { id: "others", value: "others", label: "Others (Individuals/AoP)" },
        {
          id: "tollManufacturingCompany",
          value: "tollManufacturingCompany",
          label: "Toll Manufacturing Company",
        },

        {
          id: "tollManufacturingOthers",
          value: "tollManufacturingOthers",
          label: "Toll Manufacturing Others",
        },
        {
          id: "distributorOfCigarettes",
          value: "distributorOfCigarettes",
          label: "Distributor of Cigarettes",
        },
        {
          id: "distributorOfPharmaProducts",
          value: "distributorOfPharmaProducts",
          label: "Distributor of Pharma Products",
        },
        {
          id: "saleOfRice",
          value: "saleOfRice",
          label: "Sale of Rice, Cotton Seed Oil, Edible Oil",
        },
        {
          id: "suppliesOfGold",
          value: "suppliesOfGold",
          label: "Supplies of Gold, Silver, and Article",
        },
        {
          id: "distributors",
          value: "distributors",
          label:
            "Distributors, Dealers, Sub Dealers, Wholesalers and Retailers of FMCG, Fertilizers, Electronics Excluding Mobile Phones, Sugar, Cement, Steel and Edible Oil, if they are appearing in ATL of Income Tax and Sales Tax",
        },
      ],
      nonFilerOptions: [
        {
          id: "company",
          value: "company",
          label: "Company",
        },
        {
          id: "others",
          value: "others",
          label: "Others (Individuals/AoP)",
        },
        {
          id: "tollManufacturingCompany",
          value: "tollManufacturingCompany",
          label: "Toll Manufacturing Company",
        },
        {
          id: "tollManufacturingOthers",
          value: "tollManufacturingOthers",
          label: "Toll Manufacturing Others",
        },
        {
          id: "distributorOfCigarettes",
          value: "distributorOfCigarettes",
          label: "Distributor of Cigarettes",
        },
        {
          id: "distributorOfPharmaProducts",
          value: "distributorOfPharmaProducts",
          label: "Distributor of Pharma Products",
        },
        {
          id: "saleOfRice",
          value: "saleOfRice",
          label: "Sale of Rice, Cotton Seed Oil, Edible Oil",
        },
        {
          id: "suppliesOfGold",
          value: "suppliesOfGold",
          label: "Supplies of Gold, Silver, and Article",
        },
        {
          id: "distributors",
          value: "distributors",
          label:
            "Distributors, Dealers, Sub Dealers, Wholesalers and Retailers of FMCG, Fertilizers, Electronics Excluding Mobile Phones, Sugar, Cement, Steel and Edible Oil, if they are appearing in ATL of Income Tax and Sales Tax",
        },
      ],
      dummyData: [
        { ntn: "0000123-4", name: "ABC Company" },
        { ntn: "0000567-8", name: "XYZ Enterprises" },
        { cnic: "2312398765432", name: "Red Enterprises" },
        { ntn: "0000987-6", name: "Green Solutions" },
        { cnic: "3214569876543", name: "Blue Technologies" },
        { ntn: "0000678-9", name: "Yellow Services" },
        { cnic: "4567893216549", name: "Purple Products" },
      ],

      salesTaxDummyData: [
        { ntn: "111222333", name: "Testing Company " },
        { ntn: "444555666", name: "Registered Company 2" },
      ],
    };
  },
  methods: {
    handleStatusChange() {
      if (this.selectedStatus !== "Not Confirmed") {
        this.showAdditionalFields = false;
        this.isVerified = true;
        this.selectedIdType = ""; // Reset ID type selection
      } else {
        this.showAdditionalFields = true;
        this.isVerified = false;
        this.ntnInput = "";
        //this.verifiedName = ""; // Clear the name when "Not Confirmed" is selected
      }
    },

    handleIdTypeChange() {
      // Clear the input fields when ID type changes
      if (this.selectedIdType === "ntn") {
        this.ntnInput = "";
      } else if (this.selectedIdType === "cnic") {
        this.cnicInput = "";
      }
    },
    formatNTNInput() {
      // Remove all characters except letters, digits, and hyphen
      let cleaned = this.ntnInput.replace(/[^a-zA-Z0-9-]/g, "");

      // Ensure the NTN is formatted correctly
      if (cleaned.length > 9) {
        cleaned = cleaned.slice(0, 9); // Limit to 9 characters including hyphen
      }

      // Insert hyphen if it doesn't already exist and it's appropriate
      if (cleaned.length > 6 && !cleaned.includes("-")) {
        cleaned = cleaned.slice(0, 7) + "-" + cleaned.slice(7);
      }

      // Update the model value with the formatted NTN
      this.ntnInput = cleaned;
    },
    formatCNICInput() {
      // Remove all characters except digits
      let cleaned = this.cnicInput.replace(/\D/g, "");

      // Ensure the CNIC is exactly 13 digits
      if (cleaned.length > 13) {
        cleaned = cleaned.slice(0, 13);
      }

      // Update the model value with the formatted CNIC
      this.cnicInput = cleaned;
    },
    formatNTNInputSales() {
      let cleaned = this.ntnInputForSalesTax.replace(/\D/g, "");

      if (cleaned.length > 7) {
        cleaned = cleaned.slice(0, 7);
      }

      this.ntnInputForSalesTax = cleaned;
    },

    verifyNotConfirmed() {
      let entry;
      if (this.selectedIdType === "ntn") {
        entry = this.dummyData.find((item) => item.ntn === this.ntnInput);
      } else if (this.selectedIdType === "cnic") {
        entry = this.dummyData.find((item) => item.cnic === this.cnicInput);
      }

      if (entry) {
        this.selectedStatus = "Filer";
        this.generalVerifiedName = entry.name;
        this.selectedVendorStatus = "";
        this.showAdditionalFields = true;
        this.isVerified = true;
      } else {
        alert("ID not found");
        this.showAdditionalFields = false;
        this.generalVerifiedName = "";
        this.isVerified = false;
      }
    },

    verifyNTN() {
      const entry = this.dummyData.find((item) => item.ntn === this.ntnInput);

      if (entry) {
        this.selectedStatus = "Filer"; // Hardcode to Filer for all dummyData entries
        this.selectedVendorStatus = "";
        this.generalVerifiedName = entry.name;
        this.showAdditionalFields = true;
      } else {
        alert("NTN not found or invalid");
        this.showAdditionalFields = false;
        this.generalVerifiedName = "";
      }
    },

    handleSalesTaxStatusChange() {
      if (this.selectedSalesTaxStatus !== "notConfirmed") {
        this.ntnInputForSalesTax = "";
        this.salesTaxVerifiedName = "";
      }
      this.calculateGSTWithholding();
    },
    verifySalesTaxNTN() {
      const salesTaxEntry = this.salesTaxDummyData.find(
        (item) => item.ntn === this.ntnInputForSalesTax
      );
      if (salesTaxEntry) {
        this.selectedSalesTaxStatus = "registered";
        this.salesTaxVerifiedName = salesTaxEntry.name;
      } else {
        this.selectedSalesTaxStatus = "unregistered";
        this.salesTaxVerifiedName = "";
      }
    },

    calculateIncomeTaxWithholding() {
      if (!this.selectedStatus) {
        alert(
          "Please select a valid Vendor Status and Filer/Non-Filer option."
        );
        return;
      }
      const vendorStatus = this.selectedVendorStatus || "default";
      const rate =
        this.selectedStatus === "Filer"
          ? this.taxRates.filer[vendorStatus]
          : this.taxRates.nonFiler[vendorStatus];

      this.incomeTaxWithholding = this.grossInvoiceAmount * rate;
      this.calculateGSTWithholding();
    },

    calculateGSTWithholding() {
      if (this.selectedSalesTaxStatus === "unregistered") {
        this.gstWithholding = this.grossInvoiceAmount * 0.05;
      } else if (this.selectedSalesTaxStatus === "registered") {
        this.gstWithholding = 0;
      } else {
        this.gstWithholding = 0;
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    updateGrossInvoiceAmount(event) {
      if (!this.selectedStatus || !this.selectedVendorStatus) {
        alert(
          "Please select both a status and a vendor category before entering the Gross Invoice Amount."
        );
        return;
      }
      const inputValue = event.target.value.replace(/,/g, "");
      this.grossInvoiceAmount = parseFloat(inputValue) || 0;
      this.calculateIncomeTaxWithholding();
    },
    getMondayOfCurrentWeek() {
      const currentDate = new Date();
      const day = currentDate.getDay();
      const distanceToMonday = day === 0 ? -6 : 1 - day;
      const monday = new Date(currentDate);
      monday.setDate(currentDate.getDate() + distanceToMonday);
      const formattedDate = monday.toLocaleDateString("en-GB"); // Format as "DD/MM/YYYY"
      return `Status active as at ${formattedDate}`;
    },
  },
  computed: {
    payableToVendor() {
      return (
        this.grossInvoiceAmount -
        this.incomeTaxWithholding -
        this.gstWithholding
      );
    },
    formattedGrossInvoiceAmount() {
      return this.numberWithCommas(this.grossInvoiceAmount);
    },
    formattedIncomeTaxWithholding() {
      return this.numberWithCommas(this.incomeTaxWithholding);
    },
    formattedGSTWithholding() {
      return this.numberWithCommas(this.gstWithholding);
    },
    formattedPayableToVendor() {
      return this.numberWithCommas(this.payableToVendor);
    },
  },
};
</script>

<style scoped>
.container-fluid {
  margin-bottom: 20px;
}
.InputFields {
  width: 50vw;
  margin-bottom: 10px;
}
.radioButtons {
  width: 50vw;
}
.SalesTaxPurpose {
  width: 30vw;
}
@media (max-width: 992px) {
  .InputFields {
    width: 75vw;
  }
  .radioButtons {
    width: 75vw;
  }
  .SalesTaxPurpose {
    width: 75vw;
  }
}
@media (max-width: 767px) {
  .InputFields {
    width: 90vw;
  }
  .radioButtons {
    width: 90vw;
  }
  .SalesTaxPurpose {
    width: 90vw;
  }
}
.VendorStatusRadio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
